import { latLng } from 'leaflet'
import { fromSnakeCaseToTitleCase } from '@/utils'

export function getGeoObject(str) {
  const [lat, lng] = str.split(',')

  return {
    string: str,
    lngLat: [lng, lat],
    latLng: [lat, lng],
    object: { lng, lat },
    leaflet: latLng(lat, lng),
  }
}

// get vehicle modes object from api response
export function getVehicleModes(r) {
  const of = r?.operational_flags

  return {
    // is_available: r?.is_available || false,
    // is_not_available: !r?.is_available || true,

    // rebalance: of?.rebalance || false,
    // // charging_pick: of?.charging_pick || false,
    // maintainance: of?.maintainance || false,
    // swap_battery: of?.swap_battery || false,

    AV: r?.is_available || false,

    RB: of?.rebalance || false,
    MT: of?.maintainance || false,
    SB: of?.swap_battery || false,

    // keeping it as the last element so RB, MT & SB can have higher priority
    // as we'll end the loop early for retrieving vehicleMode()
    UV: !r?.is_available || true,
  }
}

// get the active key (the-first-truthy-one) from vehicle modes object
export function getVehicleMode(modes) {
  // const map = {
  //   is_available: 'AV',
  //   is_not_available: 'UV',
  //   maintainance: 'MT',
  //   rebalance: 'RB',
  //   swap_battery: 'SB',
  // }

  let current
  for (const [key, value] of Object.entries(modes)) {
    if (value === true) {
      current = key
      break // end early
    }
  }
  // return map[current]
  return current
}

export function getAskDownOptions(type) {
  if (type === 'vehicleModes') {
    return [
      {
        title: 'Available',
        text: 'Available',
        value: 'AV',
        shapeColor: 'green',
      },
      {
        title: 'Unavailable',
        text: 'Unavailable',
        value: 'UV',
        shapeColor: '#D1D5DB',
      },
      {
        title: 'Rebalance',
        text: 'Rebalance',
        value: 'RB',
        shapeColor: 'blue',
      },
      {
        title: 'Maintenance',
        text: 'Maintenance',
        value: 'MT',
        shapeColor: 'purple',
      },
      {
        title: 'Swap Battery',
        text: 'Swap Battery',
        value: 'SB',
        shapeColor: 'orange',
      },
    ]
  } else if (type === 'hardwareActions') {
    return [
      {
        title: 'Lock',
        text: 'Lock',
        value: 'lock',
        shapeColor: 'white',
      },
      // {
      //   title: 'Force Lock',
      //   text: 'Force Lock',
      //   value: 'forceLock',
      //   shapeColor: 'white',
      // },
      {
        title: 'Unlock',
        text: 'Unlock',
        value: 'unlock',
        shapeColor: 'white',
      },
      // {
      //   title: 'Force Unlock',
      //   text: 'Force Unlock',
      //   value: 'forceUnlock',
      //   shapeColor: 'white',
      // },
      {
        title: 'Headlight On',
        text: 'Headlight On',
        value: 'headlightOn',
        shapeColor: 'white',
      },
      {
        title: 'Headlight Off',
        text: 'Headlight Off',
        value: 'headlightOff',
        shapeColor: 'white',
      },
      {
        title: 'Taillight ON',
        text: 'Taillight ON',
        value: 'taillightOn',
        shapeColor: 'white',
      },
      {
        title: 'Taillight Off',
        text: 'Taillight Off',
        value: 'taillightOff',
        shapeColor: 'white',
      },
      {
        title: 'Throttle ON',
        text: 'Throttle ON',
        value: 'throttleOn',
        shapeColor: 'white',
      },
      {
        title: 'Throttle Off',
        text: 'Throttle Off',
        value: 'throttleOff',
        shapeColor: 'white',
      },
      {
        title: 'Trigger Ring',
        text: 'Trigger Ring',
        value: 'triggerRing',
        shapeColor: 'white',
      },
    ]
  }
}

export function serializeVehicleResponse(r) {
  const vehicleModes = getVehicleModes(r)
  const SF = r.status_flags
  const GF = r.general_flags
  const CF = r.control_flags
  const OF = r.operational_flags
  // if (r.id === 'c3f4c0f0-afb3-4626-aba0-914ce5ab918d') {
  //   console.log('sdsssss', r)
  // }
  let lastHeartbeatTime
  if (r.lock.last_heartbeat_time) {
    let timeString
    if (r.lock.last_heartbeat_time.toString().length === 10) {
      timeString = new Date(r.lock.last_heartbeat_time * 1000)
    } else {
      timeString = new Date(
        Number(r.lock.last_heartbeat_time.toString().slice(0, 10)) * 1000
      )
    }
    lastHeartbeatTime = timeString.toISOString()
  } else {
    lastHeartbeatTime = '--'
  }
  return {
    // custom props are in camelCase
    isFocused: false,
    isSelected: false,

    geo: getGeoObject(r.location),
    vehicleMode: getVehicleMode(vehicleModes),
    vehicleModes,

    // notes[{}...]
    notes: [],
    notesLoaded: false,

    // api response props are in snake_case
    id: r.id,
    name: r.name,
    qr_code: r.qr_code,
    bike_category: r.bike_category,
    scooter_extras: r.scooter_extras,
    lock: {
      lock_id: r.lock.lock_id,
      is_locked: r.lock.is_locked,
      is_operational: r.lock.is_operational,
      power_level: r?.lock?.power_level || 100,
    },

    last_loc_updated_at: r.last_loc_updated_at,
    last_heartbeat_time: lastHeartbeatTime,

    current_trip_id: r?.current_trip_id || null,
    should_send_live_position_data: r?.should_send_live_position_data || false,

    is_available: r.is_available,

    status_flags: {
      is_reserved: SF.is_reserved,
      is_on_ride: SF.is_on_ride,
      is_charging: SF.is_charging,
      is_idle: SF.is_idle,
      is_parking: SF.is_parking,
      is_illegally_parking: SF.is_illegally_parking,
    },

    general_flags: {
      missing: GF.missing,
      iot_fault: GF.iot_fault,
      geo_fence_alert: GF.geo_fence_alert,
      restricted_alert: GF.restricted_alert,
      slowzone_alert: GF.slowzone_alert,
      low_battery: GF.low_battery,
    },

    control_flags: {
      throttle: CF.throttle,
      headlight: CF.headlight,
      taillight: CF.taillight,
      lock_speed_mode: CF.lock_speed_mode,
    },

    operational_flags: {
      rebalance: OF.rebalance,
      charging_pick: OF.charging_pick,
      maintainance: OF.maintainance,
      swap_battery: OF.swap_battery,
    },
    inspection_settings: r.inspection_settings,
    battery_settings: r.battery_settings,

    fleet: r.fleet,
    task: r.task,
    vehicle_faults: r.vehicle_faults,

    service_area: r.geofence,

    // tags[{id:str, tag:str}...]
    tags: r.tags,
  }
}

export function serializeTagResponse(r) {
  // console.log(r, r.tag, r.object_id)
  return { id: r.object_id, tag: r.tag }
}

export function serializeVehicleMetaResponse(r) {
  const s = r.summary
  const p = s.percentages

  // const greens = ['is_available', 'charging_pick', 'maintenance', 'illegally_parked']

  let chart = []
  for (const key in p) {
    const obj = {
      key,
      text: fromSnakeCaseToTitleCase(key),
      count: s[key],
      percentage: parseFloat(p[key]).toFixed(),
    }

    chart.push(obj)
  }

  const sortedChart = chart.sort((a, b) => {
    return a.percentage < b.percentage
      ? 1
      : a.percentage > b.percentage
      ? -1
      : 0
  })

  return {
    ...r,
    chart: sortedChart,
  }
}

export const validFilterGroups = [
  'filterFleet',
  'filterVehicleType',
  'filterConnectivity',
  'filterLockStatus',
  'filterVehicleMode',
  'filterBatteryStatus',
  'filterVehicleStatus',
  'filterLocationPoint',
]

export function validateFilterGroup(group) {
  return new Promise((resolve, reject) => {
    if (validFilterGroups.includes(group)) {
      resolve({ group })
    } else {
      reject({ group, message: `Invalid filter group!: ${group}` })
    }
  })
}

// raw response of a vehicle item
/*  data: [
    {
      id: 'd01cab24-d20d-4db4-8bbf-aa7aac6e6389',
      name: 'Pre Mac 2',
      bike_short_id: 'WLlMV0N',
      qr_code: '3710030002',
      bike_category: 'E',
      lock: {
        id: 'b3371373-60e8-4e1f-8751-9a3bbb385a1d',
        lock_id: '863922032422611',
        qr_code: '863922032422611',
        name: 'Pre Mac 2',
        power_level: '75.000',
        is_locked: true,
        is_charging: false,
        is_operational: true,
        last_heartbeat_time: '1621512001',
        network_signal: 31,
      },
      last_connected_at: '2021-05-20 12:00:00',
      last_ride_at: '2021-05-20 11:01:08',
      last_loc_updated_at: null,
      vehicle_conditions: '--',
      heart_beat: true,
      is_available: true,
      location: '4.5836226,-74.2098924',
      position_accuracy: '0.00',
      trip_status: 'Locked',
      service_status: 'No attention required',
      general_flags: {
        id: 'abbba4e5-fea3-41c2-ae1f-3e090315b02d',
        missing: false,
        iot_fault: false,
        geo_fence_alert: false,
        restricted_alert: false,
        slowzone_alert: false,
        low_battery: false,
        bike: 'd01cab24-d20d-4db4-8bbf-aa7aac6e6389',
      },
      operational_flags: {
        id: '5fa230a0-0f64-4cf3-9961-9d9a4f1cc567',
        rebalance: false,
        charging_pick: false,
        maintainance: false,
        swap_battery: false,
        bike: 'd01cab24-d20d-4db4-8bbf-aa7aac6e6389',
      },
      status_flags: {
        is_reserved: false,
        is_on_ride: false,
        is_charging: false,
        is_idle: false,
        is_parking: true,
        is_illegally_parking: false,
      },
      control_flags: {
        id: '66054947-915b-49ca-bbf5-9fce7690c80d',
        headlight: true,
        taillight: true,
        throttle: true,
        lock_speed_mode: false,
        bike: 'd01cab24-d20d-4db4-8bbf-aa7aac6e6389',
      },
      odometer: 0,
      remaining_range: 0,
      service_area: {
        id: 'fece75ac-bd64-4182-8148-fa2cc2b9b264',
        name: 'PreMac - Colombia',
        coords:
          '{1.5126329158970497,-79.31521944007679},{-0.508669033209467,-74.83279756507679},{-3.1438157574554118,-69.73514131507679},{0.5460035204358427,-69.20779756507679},{2.6544367187088693,-67.01053194007679},{6.421199666327291,-67.80154756507679},{9.033739281970993,-73.07498506507679},{11.799599461549509,-72.02029756507679},{7.903638399297497,-77.46951631507679},',
      },
      fleet: {
        id: '4dcbab1f-f953-417f-8a9b-3b8c12c9a00a',
        name: 'PreMac - Colombia',
      },
    },
  ],
*/

// import Vue from 'vue'
import { httpClient } from '@/services'
import {
  getGeoObject,
  getVehicleMode,
  getVehicleModes,
  validateFilterGroup,
} from './live-map.helpers'
import { default as VehicleConfig } from '@/config/vehicle.config'
import { serializeVehicleResponse } from './live-map.helpers'
import apiConfig from '@/config/api/config'
// import { EventBus } from '@/utils'

const _WSConnections = {
  BIKE_NOTIFICATIONS: null,
  BIKE_UPDATES: null,
}

export default {
  async fetchData({ commit, getters }) {
    const vehiclesUrl = `/dashboard/vehicles/?livemap=true`
    const fleetsUrl = `/dashboard/fleets/?dropdown=true`
    const tagsUrl = `/dashboard/entity-tags/bike/`
    // const vehiclesUrl = `/dashboard/vehicles/?x=true`

    commit('reqInit', true)
    try {
      const vehiclesRes = await httpClient.get(vehiclesUrl)
      const fleetsRes = await httpClient.get(fleetsUrl)
      const tagsRes = await httpClient.get(tagsUrl)

      // console.log({ tagsRes: tagsRes.data.data })

      commit('syncResponseVehicles', vehiclesRes.data)
      commit('syncResponseFleets', fleetsRes.data)
      commit('syncResponseTags', { data: tagsRes.data.data })

      // set filtered fleet data, on created it'll set all vehicles
      // as there's no fleet filter applied at the very beginning
      commit('syncFilteredFleet', {
        vehicles: getters.getFilteredData,
      })
      // set initial drawer data for drawer list view & markers
      commit('onDrawerListView', getters.getFilteredData)

      commit('saveFilterLastAppliedAt')

      return Promise.resolve(vehiclesRes)
    } catch (err) {
      console.error(err)
      // todo: create setError() mutation
      // commit('setError', err)
      return Promise.reject(err)
    } finally {
      commit('reqInit', false)
    }
  },

  async refetchVehicles({ commit, getters }) {
    const vehiclesUrl = `/dashboard/vehicles/?livemap=true`

    commit('refetching', true)
    try {
      const vehiclesRes = await httpClient.get(vehiclesUrl)

      commit('syncResponseVehicles', vehiclesRes.data)

      // set filtered fleet data, on created it'll set all vehicles
      // as there's no fleet filter applied at the very beginning
      commit('syncFilteredFleet', {
        vehicles: getters.getFilteredData,
      })
      // set initial drawer data for drawer list view & markers
      commit('onDrawerListView', getters.getFilteredData)

      commit('saveFilterLastAppliedAt')

      return Promise.resolve(vehiclesRes)
    } catch (err) {
      console.error(err)
      // todo: create setError() mutation
      // commit('setError', err)
      return Promise.reject(err)
    } finally {
      commit('refetching', false)
    }
  },

  // note: from drawer list view to details view -> use reset
  // for starting over again if the performance drops (most probably it'll lag
  // provided that it already has a large visible chunk )
  lazyLoadVehicles({ state, commit, getters } /* { reset = false } */) {
    // if (reset === true) {
    //   // todo: use mutation?
    //   state.listChunksVisible = []
    //   commit('syncListViewingIndex', { index: 0 })
    //   commit('syncListChunksVisible', { value: [] })
    //   state.listChunksVisible.push(chunks[0])
    //   return
    // }

    let vIndex = state.listViewingIndex
    // const chunks = getters.getVehicleListChunks.length || 0

    if (getters.getTotalChunkedDataCount < getters.getVehicleListCount) {
      vIndex++
      commit('syncListViewingIndex', { index: vIndex })
      // state.listChunksVisible.push(chunks[vIndex])
    }
  },

  /**
   * Note & GeoCoder
   */

  // this actions will fetch notes & geocoder data then merge it with the vehicle state
  async fetchVehicleData({ commit, state }, { id }) {
    const vehicle = state.resData[id]

    const entityType = 'bike'

    const reqVehicle = httpClient.get(`/dashboard/vehicles/${vehicle.id}/`)

    const reqNotes = httpClient.get(
      `/dashboard/entity-notes/${entityType}/${vehicle.id}/`
    )

    commit('reqBusy', true)

    fetch(
      `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${vehicle.geo.latLng[0]}&lon=${vehicle.geo.latLng[1]}`
    )
      .then((res) => res.json())
      .then((res) => {
        commit('syncDetailsGeocoderData', {
          data: res,
        })
      })

    return await httpClient
      .all([reqVehicle, reqNotes])
      .then(
        httpClient.spread((...responses) => {
          const resVehicle = responses[0]
          const resNotes = responses[1]

          console.log('ddd', serializeVehicleResponse(resVehicle.data).tags)
          const vehicleData = serializeVehicleResponse(resVehicle.data)

          // todo: remove this block when the backend gets ready
          // until the backend is ready to return tags with ids, we'll use the tags from the state
          if (vehicleData?.tags?.length > 0 && !vehicleData?.tags[0]?.id) {
            console.log('useTagsFromState', vehicleData.tags)
            vehicleData.tags = vehicle.tags
          }

          commit('syncVehicle', {
            id,
            data: vehicleData,
          })

          commit('syncNotes', {
            id,
            data: resNotes?.data?.data || [],
          })
        })
      )
      .catch((err) => {
        console.log({ fetchNotesEr: err })
      })
      .finally(() => {
        commit('reqBusy', false)
      })
  },

  async refetchVehicleData({ commit, dispatch }, { id }) {
    commit('reqRefetchVehicle', true)
    const url = `/dashboard/vehicles/${id}/`

    await httpClient
      .get(url)
      .then(async ({ data }) => {
        commit('syncResponseVehicle', {
          id,
          data: data,
        })
      })
      .catch((err) => {
        console.log({ refetchVehicleDataErr: err })
      })
      .finally(() => {
        commit('reqRefetchVehicle', false)
      })

    await dispatch('fetchVehicleData', { id })
  },

  // todo: refact -> parentId is entityId, entityId is noteId
  async deleteNote({ commit }, { parentId, entityId, entityType = 'bike' }) {
    const url = `/dashboard/entity-notes/${entityId}/`
    await httpClient.delete(url).then(() => {
      commit('deleteNote', { parentId, entityId, entityType })
    })
  },

  /**
   * Tag
   */

  // async syncTags({ commit }, { entityId, entityType = 'bike' }) {
  //   const url = `/dashboard/entity-tags/${entityType}/${entityId}/`
  //   await httpClient.get(url).then(({ data }) => {
  //     const tags = data.data.map((el) => serializeTagResponse(el))

  //     commit('syncTags', { entityId, data: tags })
  //   })
  // },

  // async saveTag({ commit }, { entityId, entityType = 'bike' }) {
  //   const url = `/dashboard/entity-tags/${entityType}/${entityId}/`
  //   await httpClient.post(url).then(({ data }) => {
  //     const tag = serializeTagResponse(data)

  //     commit('saveTag', { entityId, data: tag })
  //   })
  // },

  /**
   * Filters
   */

  onFilterApply({ commit, getters }, { group, isApplied = true }) {
    validateFilterGroup(group).then(({ group }) => {
      // console.log(group)
      let view = ''
      if (group === 'filterFleet') {
        view = 'LDrawerSummary'
        commit('onFilterGroupClearSelection', { except: 'filterFleet' })
        commit('syncFilteredFleet', {
          vehicles: getters.getFilteredFleetVehicles,
        })
      } else {
        view = 'LDrawerList'
      }

      commit('drawerSync', { view, visibility: true })
      commit('onFilterGroupApply', { group, isApplied })
      commit('onDrawerListView', getters.getFilteredData)
      commit('saveFilterLastAppliedAt')
      commit('syncListViewingIndex', { index: 0 })
    })
  },

  onFilterApplyMultiple({ commit, getters }, { groups }) {
    commit('drawerSync', { view: 'LDrawerList', visibility: true })

    groups.forEach((group) => {
      commit('onFilterGroupApply', { group: group.name })
    })

    commit('onDrawerListView', getters.getFilteredData)
    commit('saveFilterLastAppliedAt')
    commit('syncListViewingIndex', { index: 0 })
  },

  onFilterClear(
    { commit, getters, dispatch },
    { except = null, groups, group, isApplied = false }
  ) {
    commit('onFilterGroupClearSelection', { except, groups, group, isApplied })

    if (group === 'filterFleet') {
      commit('syncFilteredFleet', {
        vehicles: getters.getFilteredData,
      })
    }
    dispatch('onFilterApply', { group, isApplied })
    commit('syncListViewingIndex', { index: 0 })
  },

  /**
   * Bulk Actions
   */

  async hardwareAction({ commit }, { vehicles, action }) {
    console.log({ action })
    const total = Object.keys(vehicles).length

    if (total <= 0) {
      console.warn({ bulkAction: 'Empty data!', vehicles })
      return
    }

    const actionMap = {
      lock: {
        key: 'is_locked',
        value: true,
      },
      unlock: {
        key: 'is_locked',
        value: false,
      },
      headlightOn: {
        key: 'headlight',
        value: true,
      },
      headlightOff: {
        key: 'headlight',
        value: false,
      },
      taillightOn: {
        key: 'taillight',
        value: true,
      },
      taillightOff: {
        key: 'taillight',
        value: false,
      },
      throttleOn: {
        key: 'throttle',
        value: true,
      },
      throttleOff: {
        key: 'throttle',
        value: false,
      },
      triggerRing: {
        key: 'alarm',
        value: true,
      },
    }
    // todo: add error to store
    if (typeof actionMap[action] !== 'object') {
      console.warn({ bulkAction: 'Invalid action', action })
      return
    }

    commit('syncHwA', {
      total,
      processing: 0,
      succeeded: 0,
      failed: 0,
      resetLogs: true,
    })

    const method = 'post'
    const url = VehicleConfig.api.bulkUpdateStatus
    const data = new FormData()

    for (const key in vehicles) {
      // console.log({ key })
      data.append('bikes', key)

      const vehicle = vehicles[key]
      commit('addHwAProcessing', { vehicle, logs: 'Processing...' })
    }

    data.append(actionMap[action].key, actionMap[action].value)

    await httpClient({ url, method, data })
      .then(({ data }) => {
        console.log({ bulkSuccess: data })
      })
      .catch(({ response }) => {
        console.warn({ bulkErr: response })
        // commit('addHwAFailed', { vehicle, logs: response })
      })
  },

  // eslint-disable-next-line no-unused-vars
  async setVehicleMode({ commit }, { vehicles, mode, isDetect = false }) {
    // show in progress
    if (isDetect) {
      const total = Object.keys(vehicles).length

      if (total <= 0) {
        console.warn({ bulkAction: 'Empty data!', vehicles })
        return
      }
      commit('syncHwA', {
        total,
        processing: 0,
        succeeded: 0,
        failed: 0,
        resetLogs: true,
      })
    }

    const data = new FormData()
    data.append('bike_mode', mode)

    const keys = Object.keys(vehicles)
    keys.forEach((id) => {
      data.append('bikes', id)
      // show in progress
      if (isDetect) {
        const vehicle = vehicles[id]
        commit('addHwAProcessing', { vehicle, logs: 'Processing...' })
      }
    })
    // commit('syncQueue', { total, processing: 0, succeeded: 0, failed: 0 })

    const url = `/dashboard/vehicles/bulk-status-update/`

    // todo: processing commit
    await httpClient
      .post(url, data)
      .then(({ data }) => {
        // res.data, list, drawer
        console.log({ data })
        // commit('addQueueSucceeded', { vehicle, logs: data })
      })
      .catch(({ response }) => {
        console.log({ response })
        // commit('addQueueFailed', { vehicle, logs: response })
      })
  },

  /**
   * Debug | Tools
   */

  async fakeSocket({ commit, state }) {
    const timer = (ms) => new Promise((res) => setTimeout(res, ms))
    // const st = state
    async function faker() {
      // We need to wrap the loop into an async function for this to work
      for (const key in state.hwALogs) {
        // commit('liveMap/addHwASucceeded', { vehicle, logs: 'okay..' })
        // console.log('3s delay..', key)

        await timer(3000).then(() => {
          const { vehicle } = state.hwALogs[key]
          console.log({ process })

          commit('addHwASucceeded', {
            vehicle,
            logs: 'okay..',
          })
          console.log('3s delay..', key)
        })
        // then the created Promise can be awaited
      }
    }

    await faker()
  },

  async openWSConnections({ commit, getters, state }) {
    commit('onChangeRealtimeFilterStatus', { status: true })
    console.log(state)
    if (_WSConnections.BIKE_UPDATES) {
      return
    }

    const authToken = localStorage.getItem('token')
    _WSConnections.BIKE_UPDATES = new WebSocket(
      apiConfig.wsBase + 'dashboard/bike-updates/?token=' + authToken
    )

    _WSConnections.BIKE_UPDATES.addEventListener('open', () => {
      console.log(`[BIKE_UPDATES] Connection open`)
    })

    _WSConnections.BIKE_UPDATES.addEventListener('message', (...args) => {
      const wsData = JSON.parse(args[0].data)
      const vehicleId = wsData.data.id
      console.log('BU', wsData)

      console.log('selectedVehicleId', getters.getSelectedVehicleId)

      if (
        state.resData[vehicleId] &&
        getters.getSelectedVehicleId === vehicleId
      ) {
        console.log('BU syncing vehicle dataBU syncing vehicle data')
        state.realtimeDataOfSelectedVehicle.push(
          getGeoObject(wsData.data.location)
        )
        const prevData =
          state.drawer.details.id === vehicleId
            ? state.drawer.details
            : state.resData[vehicleId]
        const newVehicleModes = getVehicleModes({
          operational_flags: {
            ...prevData.operational_flags,
            ...wsData.data.operational_flags,
          },
          is_available: prevData.is_available,
        })
        const newVehicleMode = getVehicleMode(newVehicleModes)
        const newGeo = getGeoObject(wsData.data.location)
        const newData = {
          ...prevData,
          geo: newGeo,
          vehicleMode: newVehicleMode,
          vehicleModes: newVehicleModes,
          lock: {
            ...prevData.lock,
            is_locked: wsData.data.is_locked,
            power_level: wsData.data.power_level,
          },
          current_trip_id: wsData.data.current_trip_id,
          last_loc_updated_at: wsData.data.last_loc_updated_at,
          control_flags: {
            ...prevData.control_flags,
            ...wsData.data.control_flags,
          },
          general_flags: {
            ...prevData.control_flags,
            ...wsData.data.general_flags,
          },
          operational_flags: {
            ...prevData.control_flags,
            ...wsData.data.operational_flags,
          },
          status_flags: {
            ...prevData.control_flags,
            ...wsData.data.status_flags,
          },
        }
        commit('syncVehicle', {
          id: wsData.data.id,
          data: newData,
        })
        commit('syncFilteredFleet', {
          vehicles: getters.getFilteredData,
        })
        commit('onDrawerListView', getters.getFilteredData)
        commit('saveFilterLastAppliedAt')

        if (state.drawer.details.id === vehicleId) {
          commit('onDrawerDetailsView', newData)
        }

        console.log('BU newData', newData)
      }
    })

    _WSConnections.BIKE_UPDATES.addEventListener('close', () => {
      console.log(`[BIKE_UPDATES] Connection closed`)
      _WSConnections.BIKE_UPDATES = null
    })

    _WSConnections.BIKE_NOTIFICATIONS = new WebSocket(
      apiConfig.wsBase + 'dashboard/bike-notifications/?token=' + authToken
    )

    _WSConnections.BIKE_NOTIFICATIONS.addEventListener('open', () => {
      console.log(`[BIKE_NOTIFICATIONS] Connection open`)
    })

    _WSConnections.BIKE_NOTIFICATIONS.addEventListener('message', (...args) => {
      const wsData = JSON.parse(args[0].data)
      const vehicleId = wsData.bike_id
      console.log('BN', wsData)

      if (state.resData[vehicleId]) {
        const prevData =
          state.drawer.details.id === vehicleId
            ? state.drawer.details
            : state.resData[vehicleId]
        const newData = {
          ...prevData,
        }
        const cflags = ['headlight', 'taillight', 'throttle']
        for (const k in wsData.data) {
          if (cflags.includes(k)) {
            newData.control_flags = {
              ...newData.control_flags,
              [k]: wsData.data[k],
            }
          }
        }
        const lockFlags = ['is_locked']
        for (const k in wsData.data) {
          if (lockFlags.includes(k)) {
            newData.lock = {
              ...newData.lock,
              [k]: wsData.data[k],
            }
          }
        }
        commit('syncVehicle', {
          id: vehicleId,
          data: newData,
        })
        commit('syncFilteredFleet', {
          vehicles: getters.getFilteredData,
        })
        commit('onDrawerListView', getters.getFilteredData)
        commit('saveFilterLastAppliedAt')

        if (state.drawer.details.id === vehicleId) {
          commit('onDrawerDetailsView', newData)
        }

        console.log('BN newData', newData)
      }
    })

    _WSConnections.BIKE_NOTIFICATIONS.addEventListener('close', () => {
      console.log(`[BIKE_NOTIFICATIONS] Connection closed`)
      _WSConnections.BIKE_NOTIFICATIONS = null
    })
  },

  async closeWSConnections({ commit }) {
    if (!_WSConnections.BIKE_UPDATES) {
      return
    }

    _WSConnections.BIKE_UPDATES.close()
    _WSConnections.BIKE_NOTIFICATIONS.close()
    commit('onChangeRealtimeFilterStatus', { status: false })
  },
}

/**
 * httpClient exports axios client with necessary defaults
 * It also exports user data => user, profile, role & org
 */

import Swal from 'sweetalert2'
import axios from 'axios'
import store from '@/store'

// todo: do not use deprecated config
import apiConfig from '@/config/api/config'

let user, profile, role, roles, permissions, org
const ownerExpiredErrorMatchPart = 'your owner account has been expired'

const httpClient = axios

httpClient.defaults.baseURL = apiConfig.base
httpClient.defaults.headers.common['Accept'] =
  apiConfig.options.headers['Accept']
httpClient.defaults.headers.common['Content-Type'] =
  apiConfig.options.headers['Content-Type']

// Token & User info
;(function() {
  const token = store.getters['auth/accessToken']
  if (!token) {
    httpClient.defaults.headers.common['Authorization'] = null
  } else {
    httpClient.defaults.headers.common['Authorization'] = `Token ${token}`

    user = store.getters['auth/userInfo']
    profile = store.getters['auth/profileInfo']
    role = store.getters['auth/roleInfo']
    org = store.getters['auth/organizationInfo']
    roles = store.getters['auth/rolesInfo']
    permissions = store.getters['auth/permissionsInfo']
  }
})()

// API response interceptor
httpClient.interceptors.response.use(
  function(response) {
    return response
  },
  function(error) {
    console.log('login-error', { error })
    //handle 401
    if (error?.response?.status === 401) {
      console.log(401)
      store.dispatch('auth/logout')

      Swal.fire({
        iconColor: '#fff',
        iconHtml: `<i class="fa fa-exclamation-circle text-gray-400 text-5xl" aria-hidden="true"></i>`,
        title: error?.response?.data?.message ? 'Oops!' : `Session Expired`,
        text: error?.response?.data?.message
          ? error.response.data.message
          : `You session has expired, please login again`,
        confirmButtonText: `Login Now`,
        footer: ``,
        customClass: {
          title: '-mt-8',
          content: '-mt-2 mb-6 ',
          confirmButton: 'login-now-popup-button',
        },
      }).then((res) => {
        if (res.isConfirmed) {
          // todo: send current page url to redirect back after logging in
          window.location.href = '/login'
        }
      })
    }
    // handle 403
    else if (error?.response?.status === 403) {
      // console.log('try handle', error?.response?.data)
      let errMsg = error?.response?.data

      // handle owner expired middleware response globally
      if (`${errMsg}`.toLowerCase().startsWith(ownerExpiredErrorMatchPart)) {
        if (!Swal.isVisible()) {
          Swal.fire({
            iconColor: '#fff',
            iconHtml: `<i class="fa fa-exclamation-circle text-gray-400 text-5xl" aria-hidden="true"></i>`,
            title: `Owner Acccount Expired`,
            text: `Contact Admin to Reset Account Expiration`,
            confirmButtonText: `Logout`,
            footer: `<a href>Why do I have this issue?</a>`,
            customClass: {
              title: '-mt-8',
              content: '-mt-2 mb-6',
              confirmButton: 'login-now-popup-button',
            },
            allowEscapeKey: false,
            allowOutsideClick: false,
          }).then((res) => {
            if (res.isConfirmed) {
              store.dispatch('auth/logout')
              window.location.href = '/login'
            }
          })
        }
      }
    }
    return Promise.reject(error)
  }
)

export { httpClient, user, profile, role, roles, permissions, org }
export default httpClient
